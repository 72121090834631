#home-content {
  h1 {
    font-size: 40px;
    letter-spacing: 0.18px;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
  }
  ul.advantages {
    margin: 0;
    padding: 0;

    li {
      font-size: 16px;
      padding: 5px 10px 5px 35px;
      margin: 0;
      vertical-align: middle;

      background: url('../../common/assets/icons/check-circle.svg') no-repeat
        left center; /* <-- change `left` & `top` too for extra control */
      /* reset styles (optional): */
      list-style: none;
      margin: 0;
    }
  }
}
