.air-iframe {
  width: 508px;
  height: 549px;
  background-color: #f7f5f6;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 16px;

  .divider {
    background-color: #dfd9dc;
    border: 1px solid #dfd9dc;
    width: 405px;
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .disclaimer {
    color: #4f454a;
    font-family: 'Atkinson Hyperlegible';
    width: 405px;
    opacity: 0.5;
  }

  .connect-button {
    width: 405px;
    height: 48px;
    background-color: #fff;
    display: flex;
    margin-top: 24px;

    .icon-button {
      height: 100%;
      width: 58px;
      border-right: 1px solid #cbc4c7;
      display: flex;
      justify-content: center;
      align-items: center;

      .instagram {
        width: 24px;
      }

      .twitter {
        width: 29px;
      }
    }

    .action-button {
      display: flex;
      padding-left: 15px;
      align-items: center;
      width: 100%;
      height: 100%;
      font-weight: 700;
      font-size: 16px;
      font-family: 'Atkinson Hyperlegible', sans-serif;
    }

    .action-button > div {
      width: 100%;
      padding: 0;
    }
  }

  .kep-login-facebook.metro {
    background-color: #fff !important;
    border: none !important;
    color: #1a1517;
    font-weight: 700 !important;
    font-size: 16px !important;
    font-family: 'Atkinson Hyperlegible', sans-serif !important;
    height: 100% !important;
    margin: 0px !important;
    padding-left: 0px;
    text-transform: none !important;
  }
}

body {
  height: 100%;
  width: 100%;
}
