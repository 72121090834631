ul.links {
  display: flex;
  flex-direction: column;
  column-gap: 32px;
  padding: 0;
  margin: 0;
  margin-top: 20px;
  list-style: none;

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }

  li {
    a {
      display: block;
      text-decoration: none !important;
      color: #000 !important;
      font-family: Montserrat;
      font-size: 14px;
      font-style: normal;
      line-height: 33px;
      letter-spacing: -0.21px;
    }
  }
}
