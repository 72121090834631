.google-connect {
  display: flex;
  width: 304px;
  height: 352px;
  padding: 29px 24px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  background: #fff;
  flex-shrink: 0;

  color: #000;
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.169px;

  .google-connect-status {
    font-size: 14px;
    font-weight: 400;
  }

  .google-connect-title {
    h1 {
      font-size: 16px;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.169px;
    }
    div {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.169px;
      width: 287px;
    }
  }
  .google-connect-privacy {
    a {
      text-decoration: none;
      color: #000;
    }
  }
  .google-btn {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    min-width: 195px;
    max-width: 210px;
    height: 42px;
    background-color: #4285f4;
    border-radius: 2px;
    padding: 0;
    border: 0;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.25);

    .google-icon-wrapper {
      position: relative;
      margin-top: 1px;
      margin-left: 1px;
      width: 40px;
      height: 40px;
      border-radius: 2px;
      background-color: white;
    }
    .google-icon {
      margin-top: 11px;
      width: 18px;
      height: 18px;
    }
    .btn-text {
      float: right;
      margin: 12px 18px 0 0;
      color: white;
      font-size: 14px;
      letter-spacing: 0.2px;
      font-family: 'Roboto';
    }
    &:hover {
      box-shadow: 0 0 6px #4285f4;
    }
    &:active {
      background: #1669f2;
    }
  }
  .modal {
    h2 {
      margin-top: 10px;
      margin-bottom: 5px;
    }
    font-weight: 400;
    .modal-content {
      min-width: 450px;
    }
    .close:before {
      content: '';
    }
    .modal-body {
      min-width: 450px;
      text-align: left;
    }
  }
}
