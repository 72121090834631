#content {
  min-height: 500px;
  padding: 30px 0;
  width: 100%;
  background: lightgray 0px -366.5px / 100% 182.857% no-repeat;
  color: var(--branco, #fff);
  font-family: Montserrat;
  // font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.169px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: 1024px) {
    padding: 50px 0;
  }

  .container {
    width: 1077px;
    min-height: 410px;
    padding: 0 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    @media screen and (min-width: 1024px) {
      flex-direction: row;
      padding: 0 60px;
    }
  }

  .left-area {
    width: 280px;
    @media screen and (min-width: 375px) {
      width: 335px;
    }

    @media screen and (min-width: 425px) {
      width: 395px;
    }
    @media screen and (min-width: 768px) {
      width: 590px;
    }

    .requester-name,
    .requested-name {
      color: var(--branco, #fff);
      text-align: center;
      font-family: Montserrat;
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 0.169px;
    }
    .request-separator {
      margin-right: 10px;
      margin-left: 10px;
    }

    h1 {
      color: #fff;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Montserrat;
      font-size: 40px;
      font-style: normal;
      font-weight: 600;
      line-height: 143.2%; /* 57.28px */
      letter-spacing: 0.18px;
    }

    p {
      color: var(--branco, #fff);
      font-family: Montserrat;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 156%; /* 24.96px */
      letter-spacing: -1px;
    }
  }

  .right-area {
    padding-top: 30px;

    @media screen and (min-width: 1024px) {
      padding-top: 0;
    }
  }
}
