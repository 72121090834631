#header {
  display: flex;
  height: 90px;
  width: calc(100%-180px);
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin: 0px;
  padding: 0px 90px;

  @media screen and (min-width: 768px) {
    justify-content: space-between;
  }

  button {
    height: 40px;
    width: 151px;
    border-radius: 3px;
    background-color: #6a53bd;
    color: #fff;
    text-align: center;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 171.429% */
    letter-spacing: 0.169px;
    border: 0px;
    cursor: pointer;
    display: none;

    @media screen and (min-width: 768px) {
      display: block;
    }
  }
}
