@charset "UTF-8";

@font-face {
	font-style: normal;
	font-weight: normal;
	font-family: "airscoreFont";
	src: url("../../assets/icons/font/airscore-font.eot");
	src: url("../../assets/icons/font/airscore-font.eot?#iefix") format("embedded-opentype"),
		url("../../assets/icons/font/airscore-font.woff") format("woff"),
		url("../../assets/icons/font/airscore-font.ttf") format("truetype"),
		url("../../assets/icons/font/airscore-font.svg#airscore-font") format("svg");
}

.air {
	text-align: center;
	text-rendering: auto;
	display: inline-block;
	vertical-align: middle;
	text-transform: initial;
	font: normal normal normal 16px/1 airscoreFont;
	font-size: inherit;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
}
$icons: (
	"air-shared-economy": "\e94b",
	"air-arrow-down": "\e90a",
	"air-arrow-left": "\e93e",
	"air-arrow-right": "\e94a",
	"air-airscore-outline": "\e906",
	"air-logout": "\e923",
	"air-topics-outline": "\e93d",
	"air-interactions": "\e94c",
	"air-cross": "\e94d",
	"close": "\e94d",
	"air-plus": "\e94e",
	"air-edit-outline": "\e900",
	"air-edit": "\e901",
	"air-download": "\e936",
	"air-padlock": "\e949",
	"air-check-select": "\e948",
	"air-badge-one-day": "\e946",
	"air-question-mark": "\e947",
	"air-badge-ninety-days": "\e945",
	"air-badge-seven-days": "\e911",
	"air-achievements-outline": "\e941",
	"air-achievements": "\e942",
	"air-badge-thirty-days": "\e943",
	"air-interactions-outline": "\e944",
	"air-hair": "\e940",
	"air-topics": "\e904",
	"air-social-share": "\e93f",
	"air-facebook": "\e910",
	"air-instagram": "\e926",
	"air-twitter": "\e928",
	"air-views-outline": "\e929",
	"air-youtube": "\e93b",
	"air-check": "\e92f",
	"air-error-outline": "\e903",
	"air-share-outline": "\e902",
	"air-airscore": "\e905",
	"air-like": "\e907",
	"air-profile": "\e908",
	"air-retweet-outline": "\e909",
	"air-unlink-outline": "\e90b",
	"air-fitness": "\e90c",
	"air-life-family": "\e90d",
	"air-gastronomy": "\e90e",
	"air-health": "\e90f",
	"air-internet": "\e912",
	"air-kids": "\e913",
	"air-lifestyle": "\e914",
	"air-like-outline": "\e915",
	"air-literature": "\e916",
	"air-martial-art": "\e917",
	"air-media-boost": "\e918",
	"air-music": "\e919",
	"air-notification-outline": "\e91a",
	"air-nutrition": "\e91b",
	"air-pet": "\e91c",
	"air-politic": "\e91d",
	"air-profile-outline": "\e91e",
	"air-settings-outline": "\e91f",
	"air-sports": "\e920",
	"air-technology": "\e921",
	"air-tooltip": "\e922",
	"air-trash": "\e924",
	"air-travel": "\e925",
	"air-up": "\e927",
	"air-activism": "\e92a",
	"air-automotive": "\e92b",
	"air-beauty": "\e92c",
	"air-beer": "\e92d",
	"air-business": "\e92e",
	"air-close": "\e930",
	"air-comment-outline": "\e931",
	"air-construction": "\e932",
	"air-culture": "\e933",
	"air-dislike-outline": "\e934",
	"air-down": "\e935",
	"air-education": "\e937",
	"air-entertainment": "\e938",
	"air-environment": "\e939",
	"air-facebook-round": "\e93a",
	"air-fashion": "\e93c"
);

@mixin makeIcon($name, $content) {
	.#{$name}:before {
		content: $content;
	}
}

@each $name, $content in $icons {
	@include makeIcon($name, $content);
}
