// @import "slick-carousel/slick/slick.css"; 
// @import "slick-carousel/slick/slick-theme.css";
@import "./common/style/icons/icons.scss";

html {
    scroll-behavior: smooth;
    height: 100%;
}

body {
    margin: 0;
    z-index: 2;
    position: relative;
    font-family: 'Montserrat', sans-serif;
    height: 100%;
    
    iframe {
        display: none !important;
    }
}
.App {
    height: 100%;
    width: 100%;
}

#root {
    height: 100%;
    width: 100%;
}