footer {
  margin-top: 30px;
  width: calc(100%-20px);
  padding: 0px 10px;

  @media screen and (min-width: 768px) {
    width: calc(100%-180px);
    padding: 0px 90px;
  }

  .brands-content {
    margin-bottom: 30px;

    h1 {
      font-size: 32px;
    }

    .brands-images {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      gap: 15px;

      @media screen and (min-width: 1024px) {
        flex-direction: row;
        gap: unset;
      }
    }
  }
  .footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding-bottom: 20px;

    @media screen and (min-width: 768px) {
      flex-direction: row;
      align-items: flex-end;
    }

    .left-area {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      .footer-links {
        a {
          font-weight: 700;
        }
      }
    }

    .right-area {
      display: flex;
      flex-direction: column;

      .social-networks {
        display: flex;
        justify-content: center;
        padding-top: 20px;
        column-gap: 18px;

        @media screen and (min-width: 768px) {
          justify-content: flex-end;
          padding-top: 0;
        }

        .social-network-icon {
          width: 38px;
          height: 38px;
          border-radius: 50%;
          background-color: #6a53bd;
          display: flex;
          align-items: center;
          justify-content: center;

          a {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
      .rights {
        margin-top: 18px;
        color: #020000;
        text-align: right;
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 33px;
        letter-spacing: -0.21px;
      }
    }
  }
}
