.connect-button {
  width: 290px;
  min-height: 52px;
  display: flex;
  padding: 2px 2px 2px 2px;
  align-items: center;
  gap: 13.5px;
  border-radius: 8px;
  border: 0;
  cursor: pointer;

  &:disabled {
    filter: opacity(0.3);
  }
  .connect-button-icon {
    width: 52px;
    height: 52px;
    flex-shrink: 0;
    border-radius: 6px;
    background: var(--Branco, #fff);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .connect-button-text {
    color: #fff;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    width: auto;
    line-height: 143.2%; /* 22.912px */
    letter-spacing: 0.18px;
  }
}
