@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

.modal {
  // display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 999; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.modal-stretch {
  max-width: none !important;
  width: 80% !important;
}

.modal-content {
  font-family: 'Roboto';
  position: relative;
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 32px 24px;
  max-width: 271px;
  width: 50%; /* Could be more or less, depending on screen size */

  h3 {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 16px;
  }

  .modal-message {
    font-size: 14px;
    text-align: left;
    font-weight: 400;
  }

  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .modal-message {
    font-size: 14px;
    text-align: left;
    font-weight: 400;
  }

  .close {
    top: 8px;
    right: 8px;
    border: 0px;
    cursor: pointer;
    font-size: 30px;
    position: absolute;
    background-color: white;
  }
}
