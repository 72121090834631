#connect-box {
  width: 360px;
  min-height: 290px;
  padding: 29px 24px;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  border-radius: 8px;
  background: #fff;
  border: 0;
  color: #000;
  .connect-box-invalid-link {
    width: 290px;
    text-align: center;
    color: #ef4b4b;
  }
}
.connect-box-privacy {
  width: 290px;
  text-align: center;
  font-size: 13px;

  a {
    text-decoration: none;
    color: #000;
  }
}
.connect-box-status {
  width: 290px;
  margin-top: 20px;
  text-align: center;

  &::first-letter {
    text-transform: uppercase;
  }
}
.connect-box-status-success {
  color: #5da700;
}
