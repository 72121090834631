.card {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background: #fff;
  max-width: 100px;
  padding: 30px;

  .icon {
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
  }

  .text {
    color: var(--darkest, #000);
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 156%; /* 21.84px */
    letter-spacing: -1px;
  }
}
